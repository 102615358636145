export const state = () => ({
  object: null,
})

export const actions = {
  async load({ commit }, { customerId, id }) {
    const { data } = await this.$axios.$get(
      `customers/${customerId}/objects/${id}`
    )
    commit('setObject', data)
    commit('mortgages/setMortgage', data.mortgage, { root: true })

    return data
  },

  async create({ commit }, { customerId, data }) {
    const object = await await this.$axios.$post(
      `customers/${customerId}/objects`,
      data
    )
    commit('setObject', object.data)
    return object.data
  },

  async update({ commit }, { id, customerId, data }) {
    const customer = await this.$axios.$patch(
      `customers/${customerId}/objects/${id}`,
      data
    )
    commit('setObject', customer.data)
    return customer.data
  },

  async destroy({ commit }, { customerId, id }) {
    await this.$axios.$delete(`customers/${customerId}/objects/${id}`)
    commit('setObject', null)
  },

  async destroyDocument({ commit, dispatch }, { customerId, objectId, id }) {
    commit('removeDocument', id)

    await this.$axios.$delete(
      `customers/${customerId}/objects/${objectId}/documents/${id}`
    )

    dispatch('load', { customerId, id: objectId })
  },

  async save({ dispatch, commit }, { customerId, id, data }) {
    data = formatDates(this.$dateFns, data)
    data = replaceUndefinedWithNull(data)

    const objectData = {
      ...data,
      customer_id: data.customer.id,
      pension_fund_type: data.pension_fund_type?.value || null,
      third_pillar_type: data.third_pillar_type?.value || null,
    }

    if (id) {
      const object = await dispatch('update', {
        id,
        customerId,
        data: objectData,
      })

      await commit('setObject', object)

      return object
    }

    const object = await dispatch('create', { data: objectData, customerId })

    return object
  },
}

export const mutations = {
  setObject(state, value) {
    state.object = value
  },

  addDocument(state, document) {
    if (!state.object) {
      return
    }

    if (!state.object.documents) {
      state.object.documents = []
    }
    state.object.documents.push(document)
  },

  removeDocument(state, documentId) {
    state.object.documents = state.object.documents.filter(
      (document) => document.id !== documentId
    )
  },
}

export const getters = {
  object: (state) => state.object,
}

function formatDates(dateFns, data) {
  const dateFields = [
    'renovation_planned_at',
    'building_law_end_date',
    'financial_value_estimation_date',
  ]

  dateFields.forEach((field) => {
    const value = data[field]

    if (value === true) {
      // If true, use today's date
      data[field] = dateFns.format(new Date(), 'yyyy-MM-dd')
    } else if (value === false || value === null) {
      // If false, set it to null
      data[field] = null
    } else if (value instanceof Date || !isNaN(new Date(value))) {
      // If it's a valid date or parsable, format it
      data[field] = dateFns.format(value, 'yyyy-MM-dd')
    } else {
      // If invalid, set to null
      data[field] = null
    }
  })

  return data
}

function replaceUndefinedWithNull(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      data[key] = null
    }
  })

  return data
}
