import isEqual from 'lodash/isEqual'
import { format, parseISO } from 'date-fns'
import { encodeQueryParams } from '~/utils'

let loadAllCancelTokenSource = null
let statisticsCancelTokenSource = null
export const state = () => ({
  // Filter
  filter: {},
  lastFilter: null,
  statistics: null,
  ordering: {
    column: 'id',
    dir: 'asc', // or 'desc'
  },
  pagination: {
    currentPage: 1,
    lastPage: 1,
  },
  // Customer
  customer: null,
  customers: [],
  customersMeta: {},
  customerOptions: [],
  lastUpdatedCustomers: [],
  selectedContact: {},
  isLoadingStatistics: false,
})

export const actions = {
  async loadAll({ commit, state, getters }) {
    let page = state.pagination.currentPage

    const filter = getters.getFilterParams

    if (!isEqual(state.lastFilter, filter)) {
      page = 1
    }

    const params = {
      page,
      column: state.ordering.column,
      dir: state.ordering.dir,
      ...filter,
    }
    const query = encodeQueryParams(params)

    if (loadAllCancelTokenSource) {
      loadAllCancelTokenSource.cancel()
    }

    loadAllCancelTokenSource = this.$axios.CancelToken.source()

    const { data } = await this.$axios.get(`customers?${query}`, {
      cancelToken: loadAllCancelTokenSource.token,
    })
    commit('setPagination', {
      currentPage: data.meta.current_page,
      lastPage: data.meta.last_page,
    })

    commit('setCustomers', data.data)
    commit('setCustomersMeta', data.meta)
    commit('setLastFilter', filter)
  },

  async load({ commit, state }, customerId) {
    const { data } = await this.$axios.$get(`customers/${customerId}`)

    commit('setCustomer', data)
    commit(
      'setSelectedContact',
      data.contacts.find((c) => c.is_contact_person) ||
        data.contacts.find((c) => c.is_main)
    )
    return data
  },

  async loadStatistics({ getters, commit, state }) {
    commit('setIsLoadingStatistics', true)

    const query = encodeQueryParams(getters.getFilterParams)

    if (statisticsCancelTokenSource) {
      statisticsCancelTokenSource.cancel()
    }

    statisticsCancelTokenSource = this.$axios.CancelToken.source()

    const { data } = await this.$axios.$get(`customers/statistics?${query}`, {
      cancelToken: statisticsCancelTokenSource.token,
    })
    commit('setCustomerStatistics', data)

    commit('setIsLoadingStatistics', false)
  },

  save({ dispatch }, { id, data }) {
    const customer = {
      ...data,
      gender: data.gender?.value || null,
      birthdate: data.birthdate ? format(data.birthdate, 'yyyy-MM-dd') : null,
      // Important to do it this way. Because when the recruited_from is undefinded it is not sent in
      // the request. But when it is null, it is sent and gets set null in database.
      recruited_from: data.recruited_from
        ? data.recruited_from.value
        : data.recruited_from,
      phone: data.phone?.replace(/ /g, ''),
      phone_2: data.phone_2?.replace(/ /g, ''),
      mobile: data.mobile?.replace(/ /g, ''),
      tags: data.tags?.map((tag) => tag.label),
    }

    if (data.created_at) {
      customer.created_at = format(data.created_at, 'yyyy-MM-dd')
    }

    if (id) {
      return dispatch('update', {
        id,
        data: customer,
      })
    }

    return dispatch('create', customer)
  },

  async create({ commit }, customer) {
    const { data } = await this.$axios.$post('customers', customer)
    commit('setCustomer', data)
    return data
  },

  async update({ commit }, { id, data }) {
    const customer = await this.$axios.$patch(`customers/${id}`, data)
    commit('setCustomer', customer.data)
    return customer.data
  },

  async archive({ commit }, customerId) {
    await this.$axios.$post(`customers/${customerId}/archive`)
    commit('setCustomer', null)
  },

  async destroy({ commit }, customerId) {
    await this.$axios.$delete(`customers/${customerId}`)
    commit('setCustomer', null)
  },

  async updateOrdering({ commit, dispatch }, ordering) {
    commit('setOrdering', ordering)
    await dispatch('loadAll')
  },

  async changePage({ state, commit, dispatch }, nextPage) {
    const pagination = { ...state.pagination }
    pagination.currentPage = nextPage
    commit('setPagination', pagination)
    await dispatch('loadAll')
  },

  async loadCustomerOptions({ commit }, filters) {
    const params = {
      page: 1,
      ...filters,
    }
    const query = encodeQueryParams(params)

    const { data } = await this.$axios.get(`customers?${query}`)
    commit('setCustomerOptions', data.data)
  },

  async loadLastUpdated({ commit, rootGetters, rootState }, consultantId) {
    const lastUpdatedCustomersQuery = encodeQueryParams({
      page: 1,
      column: 'updated_at',
      dir: 'desc',
      filterConsultantId: consultantId,
      filterVisited: false,
    })

    const lastUpdatedCustomers = await this.$axios.get(
      `customers?${lastUpdatedCustomersQuery}`
    )

    commit('setLastUpdatedCustomers', lastUpdatedCustomers.data.data)
  },

  async destroyDocument({ commit, dispatch }, { customerId, id }) {
    commit('removeDocument', id)

    await this.$axios.$delete(`customers/${customerId}/documents/${id}`)

    dispatch('load', customerId)
  },
}

export const mutations = {
  setCustomers(store, customers) {
    store.customers = customers
  },
  setCustomersMeta(store, meta) {
    store.customersMeta = meta
  },
  setCustomer(store, customer) {
    store.customer = customer
      ? {
          ...customer,
          contacts: customer.contacts?.map((contact) => ({
            ...contact,
            selected: contact.selected || false,
          })),
        }
      : null
  },
  setCustomerStatistics(store, statisticData) {
    store.statistics = statisticData
  },
  setFilter(state, value) {
    state.filter = JSON.parse(JSON.stringify(value))
  },
  setOrdering(store, ordering) {
    store.ordering = ordering
  },
  setPagination(store, pagination) {
    store.pagination = pagination
  },
  setCustomerOptions(store, customers) {
    store.customerOptions = customers
  },
  setLastUpdatedCustomers(state, customers) {
    state.lastUpdatedCustomers = customers
  },
  setLastFilter(state, lastFilterObject) {
    state.lastFilter = lastFilterObject
  },

  addDocument(state, document) {
    if (!state.customer) {
      return
    }

    if (!state.customer.documents) {
      state.customer.documents = []
    }
    state.customer.documents.push(document)
  },

  removeDocument(state, documentId) {
    state.customer.documents = state.customer.documents.filter(
      (document) => document.id !== documentId
    )
  },

  setLoadAllCancelTokenSource(state, token) {
    state.loadAllCancelTokenSource = token
  },

  setStatisticsCancelTokenSource(state, token) {
    state.statisticsCancelTokenSource = token
  },

  setIsLoadingStatistics(state, value) {
    state.isLoadingStatistics = value
  },

  setSelectedContact(state, contact) {
    state.selectedContact = contact
  },
}

export const getters = {
  isLoadingStatistics(state) {
    return state.isLoadingStatistics
  },

  getFilterParams(state) {
    const filter = state.filter
    const filterParams = {
      // Customer
      filterCustomers: filter.customers,
      filterConsultantId: filter.consultantId?.id,
      filterPriorities: filter.priorities?.value,
      filterRecruitmentSources: filter.recruitmentSources?.value,
      // Tranche
      filterTrancheStates: filter.trancheStates?.value,
      filterTrancheInstituteId: filter.trancheInstituteId?.value,
      filterTrancheInstituteConsultantId:
        filter.trancheInstituteConsultantId?.value,
      filterTrancheInstituteProducts: filter.trancheInstituteProducts?.value,
      filterTrancheProvisionPaid: filter.trancheProvisionPaid?.value,
      // Object Insurance
      filterInsuranceStates: filter.insuranceStates?.value,
      filterInsuranceInstituteId: filter.insuranceInstituteId?.value,
      filterInsuranceInstituteConsultantId:
        filter.insuranceInstituteConsultantId?.value,
      filterInsuranceInstituteProducts:
        filter.insuranceInstituteProducts?.value,
      filterInsuranceOneTimeProvisionPaid:
        filter.insuranceOneTimeProvisionPaid?.value,
      // Pension
      filterPensionStates: filter.pensionStates?.value,
      filterPensionInstituteId: filter.pensionInstituteId?.value,
      filterPensionInstituteConsultantId:
        filter.pensionInstituteConsultantId?.value,
      filterPensionInstituteProducts: filter.pensionInstituteProducts?.value,
      filterPensionProvisionPaid: filter.pensionProvisionPaid?.value,
    }
    // Customer
    formatDateFilters(filter, 'creation', filterParams, 'filterCreation')

    // Tranche
    formatDateFilters(
      filter,
      'trancheDueDate',
      filterParams,
      'filterTrancheDueDate'
    )
    formatDateFilters(
      filter,
      'trancheValidFrom',
      filterParams,
      'filterTrancheValidFrom'
    )
    // Insurance
    formatDateFilters(
      filter,
      'insuranceDueDate',
      filterParams,
      'filterInsuranceDueDate'
    )
    formatDateFilters(
      filter,
      'insuranceValidFrom',
      filterParams,
      'filterInsuranceValidFrom'
    )
    formatDateFilters(
      filter,
      'trancheCommissionExpectedAt',
      filterParams,
      'filterTrancheCommissionExpectedAt'
    )
    formatDateFilters(
      filter,
      'insuranceOneTimeProvisionExpectedAt',
      filterParams,
      'filterInsuranceOneTimeProvisionExpectedAt'
    )
    // Pension
    formatDateFilters(
      filter,
      'pensionDueDate',
      filterParams,
      'filterPensionDueDate'
    )
    formatDateFilters(
      filter,
      'pensionValidFrom',
      filterParams,
      'filterPensionValidFrom'
    )
    formatDateFilters(
      filter,
      'pensionProvisionExpectedAt',
      filterParams,
      'filterPensionProvisionExpectedAt'
    )

    if (filter.customerTags?.length > 0) {
      filterParams.filterCustomerTags = filter.customerTags.map(
        (tag) => tag.slug
      )
    }

    const cleanFilters = Object.fromEntries(
      Object.entries(filterParams)
        .filter(([_, v]) => v !== undefined)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== '')
    )
    return cleanFilters
  },

  getFilterValues(state) {
    const filter = state.filter
    const filterValues = {
      filterCustomers: filter.customers,
      filterConsultantId: filter.consultantId?.name,
      filterPriorities: filter.priorities?.label,
      filterRecruitmentSources: filter.recruitmentSources?.label,
      // Tranches
      filterTrancheStates: filter.trancheStates?.label,
      filterTrancheInstituteId: filter.trancheInstituteId?.label,
      filterTrancheInstituteConsultantId:
        filter.trancheInstituteConsultantId?.label,
      filterTrancheInstituteProducts: filter.trancheInstituteProducts?.label,
      filterTrancheProvisionPaid: filter.TrancheProvisionPaid?.label,
      // Object Insurance
      filterInsuranceStates: filter.insuranceStates?.label,
      filterInsuranceInstituteId: filter.insuranceInstituteId?.label,
      filterInsuranceInstituteConsultantId:
        filter.insuranceInstituteConsultantId?.label,
      filterInsuranceInstituteProducts:
        filter.insuranceInstituteProducts?.label,
      filterInsuranceOneTimeProvisionPaid:
        filter.insuranceOneTimeProvisionPaid?.label,
      // Pension
      filterPensionStates: filter.pensionStates?.label,
      filterPensionInstituteId: filter.pensionInstituteId?.label,
      filterPensionInstituteConsultantId:
        filter.pensionInstituteConsultantId?.label,
      filterPensionInstituteProducts: filter.pensionInstituteProducts?.label,
      filterPensionProvisionPaid: filter.pensionProvisionPaid?.label,
    }

    // Customer
    formatDateFilters(filter, 'creation', filterValues, 'filterCreation')
    // Tranche
    formatDateFilters(
      filter,
      'trancheDueDate',
      filterValues,
      'filterTrancheDueDate'
    )
    formatDateFilters(
      filter,
      'trancheValidFrom',
      filterValues,
      'filterTrancheValidFrom'
    )
    formatDateFilters(
      filter,
      'trancheCommissionExpectedAt',
      filterValues,
      'filterTrancheCommissionExpectedAt'
    )
    // Insurance
    formatDateFilters(
      filter,
      'insuranceDueDate',
      filterValues,
      'filterInsuranceDueDate'
    )
    formatDateFilters(
      filter,
      'insuranceValidFrom',
      filterValues,
      'filterInsuranceValidFrom'
    )
    formatDateFilters(
      filter,
      'insuranceOneTimeProvisionExpectedAt',
      filterValues,
      'filterInsuranceOneTimeProvisionExpectedAt'
    )
    // Pension
    formatDateFilters(
      filter,
      'pensionDueDate',
      filterValues,
      'filterPensionDueDate'
    )
    formatDateFilters(
      filter,
      'pensionValidFrom',
      filterValues,
      'filterPensionValidFrom'
    )
    formatDateFilters(
      filter,
      'pensionProvisionExpectedAt',
      filterValues,
      'filterPensionProvisionExpectedAt'
    )

    if (filter.customerTags?.length > 0) {
      filterValues.filterCustomerTags = filter.customerTags
        .map((tag) => tag.slug)
        .join(', ')
    }

    const cleanFilters = Object.fromEntries(
      Object.entries(filterValues)
        .filter(([_, v]) => v !== undefined)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== '')
    )

    return cleanFilters
  },

  // The main contact on the customer does not load all the relations therefore we get the main contact from the contacts array
  mainContact(state) {
    return state.customer?.contacts?.find((contact) => contact.is_main)
  },

  // The contact person on the customer does not load all the relations therefore we get the main contact from the contacts array
  contactPerson(state) {
    return state.customer?.contacts?.find(
      (contact) => contact.is_contact_person
    )
  },

  contactsWithoutMain(state) {
    return state.customer?.contacts?.filter((contact) => {
      return !contact.is_main
    })
  },

  customerOptions(state) {
    return state.customerOptions.map((customer) => ({
      label: customer.name,
      name: customer.name,
      value: customer.id,
      id: customer.id,
    }))
  },
  customersWithNewRequests: (state) => state.customersWithNewRequests,
  customersWithOpenRequests: (state) => state.customersWithOpenRequests,
  customer: (state) => state.customer,
  lastUpdatedCustomers: (state) => state.lastUpdatedCustomers,
  customers: (state) => state.customers,
  selectedContact: (state) => state.selectedContact,
}

function formatDateFilters(filter, key, filterParams, prefix) {
  if (filter?.[key]?.start) {
    filterParams[`${prefix}Start`] = format(
      parseISO(filter[key].start),
      'yyyy-MM-dd'
    )
  }

  if (filter?.[key]?.end) {
    filterParams[`${prefix}End`] = format(
      parseISO(filter[key].end),
      'yyyy-MM-dd'
    )
  }
}
